import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import moment from 'src/utils/moment';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	TableRow,
	Typography,
} from '@material-ui/core';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import getInitials from 'src/utils/getInitials';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {},
	title: {
		padding: theme.spacing(2, 0)
	},
	seeAllButton: {
		marginTop: 18
	},
	content: {
		padding: theme.spacing(0, 2)
	},
	inner: {
		minWidth: 600
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1.5)
	},
	actions: {
		padding: theme.spacing(1),
		justifyContent: 'flex-end'
	},
}));

const Results = (props) => {
	const {
		className,
		objects,
		total,
		onChangePage,
		onChangeRowsPerPage,
		onSorting,
		rows,
		...rest
	} = props;

	const classes = useStyles();
	const { protocol, host } = window.location;
	const headerCells = [
		{
			id: 'name', label: locale.t('member.members.item_col'), align: 'left', sorting: true
		},
		// {
		// 	id: 'group_name', label: locale.t('member.members.item_col2'), align: 'left', sorting: true
		// },
		{
			id: 'diamonds', label: 'เพชร', align: 'center', sorting: false
		},
		{
			id: 'live_durations', label: 'เวลา LIVE (นาที)', align: 'center', sorting: false
		},
		{
			id: 'live_days', label: 'วัน LIVE เข้าเกณฑ์ ', align: 'center', sorting: false
		},
		{
			id: 'followers', label: 'ผู้ติดตาม', align: 'center', sorting: false
		},
		{
			id: 'live_videos', label: 'วิดีโอ LIVE', align: 'center', sorting: false
		},
		{
			id: 'last_login_date', label: locale.t('member.members.item_col4'), align: 'left', sorting: true
		},
	];
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(headerCells[0].id);

	const [rowsPerPage] = useState(rows);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		const newOrder = isAsc ? 'desc' : 'asc';
		setOrder(newOrder);
		setOrderBy(property);
		onSorting(newOrder, property);
	};

	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Card>
				<CardHeader
					title={(
						<Typography
							className={classes.title}
							variant="h4"
						>
							<strong>{locale.t('overview.member.team')}</strong>
						</Typography>
					)}
					// action={(
					// 	<Button
					// 		className={classes.seeAllButton}
					// 		component={RouterLink}
					// 		to="/members"
					// 		variant="outlined"
					// 		endIcon={<ChevronRightRoundedIcon />}
					// 	>
					// 		{locale.t('btn.see_all')}
					// 	</Button>
					// )}
				/>
				<CardContent className={classes.content}>
					<Divider />
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow>
										{headerCells.map((headerCell) => (
											<TableCell
												key={headerCell.id}
												align={headerCell.align ? headerCell.align : 'center'}
												sortDirection={orderBy === headerCell.id ? order : false}
											>
												{headerCell.sorting && (
													<TableSortLabel
														active={orderBy === headerCell.id}
														direction={orderBy === headerCell.id ? order : 'asc'}
														onClick={createSortHandler(headerCell.id)}
													>
														<strong>{headerCell.label}</strong>
													</TableSortLabel>
												)}
												{!headerCell.sorting && <strong>{headerCell.label}</strong>}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{objects.slice(0, rowsPerPage).map((object) => (
										<TableRow
											hover
											key={object.id}
										>
											<TableCell>
												<div className={classes.nameCell}>
													<Avatar
														className={classes.avatar}
														src={object.image_url}
														variant="circular"
													>
														{getInitials(object.name)}
													</Avatar>
													<div>
														<Link
															color="inherit"
															component={RouterLink}
															to="#"
															variant="h6"
														>
															<strong>{object.name}</strong>
														</Link>
														{/* <br />
														<Link
															color="textSecondary"
															href={`${protocol}//${object.sub_domain}.${host.substring(host.indexOf('.') + 1)}`}
															target="_blank"
														>
															{object.sub_domain}
															{`.${host.substring(host.indexOf('.') + 1)}`}
														</Link> */}
													</div>
												</div>
											</TableCell>
											{/* <TableCell>{object.group_name}</TableCell> */}
											<TableCell align="center">{object.diamonds}</TableCell>
											<TableCell align="center">{parseInt(object.live_durations / 60, 0)}</TableCell>
											<TableCell align="center">{object.live_days}</TableCell>
											<TableCell align="center">{object.followers}</TableCell>
											<TableCell align="center">{object.live_videos}</TableCell>
											<TableCell align="left">
												<Typography
													color="textSecondary"
												>
													{object.last_login_date ? moment(object.last_login_date).fromNow() : '-'}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
			</Card>
		</div>
	);
};

Results.propTypes = {
	className: PropTypes.string,
	objects: PropTypes.array,
	onChangePage: PropTypes.func,
	onChangeRowsPerPage: PropTypes.func,
	onSorting: PropTypes.func,
	rows: PropTypes.number,
	total: PropTypes.number
};

Results.defaultProps = {
	objects: [],
	total: 0
};

export default Results;

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';

import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import VideoLibraryRoundedIcon from '@material-ui/icons/VideoLibraryRounded';
import FlightTakeoffRoundedIcon from '@material-ui/icons/FlightTakeoffRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import GolfCourseRoundedIcon from '@material-ui/icons/GolfCourseRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';

import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';

import PermMediaRoundedIcon from '@material-ui/icons/PermMediaRounded';

import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import LocalMallIcon from '@material-ui/icons/LocalMall';

import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';

import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import FaceIcon from '@material-ui/icons/Face';

import AssessmentIcon from '@material-ui/icons/Assessment';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';

import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import ImportantDevicesRoundedIcon from '@material-ui/icons/ImportantDevicesRounded';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';

import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';

import PhoneIcon from '@material-ui/icons/Phone';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import MotorcycleOutlinedIcon from '@material-ui/icons/MotorcycleOutlined';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';

import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';

import Label from 'src/components/Label';

const { REACT_APP_VERSION } = process.env;

export default [
	{
		subheader: 'nav.data.default',
		items: [
			{
				id: 'overview',
				title: 'nav.data.overview',
				href: '/overview',
				icon: AppsIcon,
				label: () => <Label color={colors.green.A100}>{`V${REACT_APP_VERSION}`}</Label>
			},
			// {
			// 	title: 'บริษัท',
			// 	href: '/content/articles/22',
			// 	icon: BusinessRoundedIcon
			// },
			// {
			// 	title: 'สินค้า',
			// 	href: '/content/articles/categories/1',
			// 	icon: LocalOfferRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'Liv',
			// 			href: '/content/articles/1'
			// 		},
			// 		{
			// 			title: 'GLO',
			// 			href: '/content/articles/2'
			// 		},
			// 		{
			// 			title: 'GEN3',
			// 			href: '/content/articles/3'
			// 		}
			// 	]
			// },
			// {
			// 	title: 'ท่องเที่ยว',
			// 	href: '/content/articles/categories/2',
			// 	icon: FlightTakeoffRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'โรงแรมราคาพิเศษ',
			// 			href: '/content/articles/4'
			// 		},
			// 		{
			// 			title: 'เรือสำราญ',
			// 			href: '/content/articles/5'
			// 		},
			// 	]
			// },
			// {
			// 	title: 'แผนรายได้',
			// 	href: '/content/articles/categories/3',
			// 	icon: AccountBalanceWalletRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'รายได้พันธมิตร',
			// 			href: '/content/articles/6'
			// 		},
			// 		{
			// 			title: 'เงื่อนไขคุณสมบัติ',
			// 			href: '/content/articles/7'
			// 		},
			// 	]
			// },
			// {
			// 	title: 'วิธีการใช้งานเว็บไซต์',
			// 	href: '/content/articles/categories/4',
			// 	icon: AssignmentRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'วิธีการสมัครสมาชิก',
			// 			href: '/content/articles/8'
			// 		},
			// 		{
			// 			title: 'วิธีการรับคอมมิชชั่น',
			// 			href: '/content/articles/9'
			// 		},
			// 		{
			// 			title: 'วิธีการรักษายอด',
			// 			href: '/content/articles/10'
			// 		},
			// 		{
			// 			title: 'วิธีการซื้อสินค้า',
			// 			href: '/content/articles/11'
			// 		},
			// 		{
			// 			title: 'วิธีการอัพเกรด',
			// 			href: '/content/articles/12'
			// 		},
			// 		{
			// 			title: 'วิธีการแชร์ลิงค์สมัคร',
			// 			href: '/content/articles/13'
			// 		},
			// 	]
			// },
			// {
			// 	title: 'การตลาด',
			// 	href: '/content/articles/categories/5',
			// 	icon: GolfCourseRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'เว็บไซต์ส่วนตัว',
			// 			href: '/content/articles/14'
			// 		},
			// 		{
			// 			title: 'โฆษณารูป',
			// 			href: '/content/articles/15'
			// 		},
			// 		{
			// 			title: 'โฆษณาวิดีโอ',
			// 			href: '/content/articles/16'
			// 		},
			// 	]
			// },
			// {
			// 	title: 'อบรม',
			// 	href: '/content/articles/categories/6',
			// 	icon: VideoLibraryRoundedIcon,
			// 	items: [
			// 		{
			// 			title: 'ก้าวแรกกับ Jifu',
			// 			href: '/content/articles/17'
			// 		},
			// 		{
			// 			title: 'การตลาดออนไลน์',
			// 			href: '/content/articles/18'
			// 		},
			// 		{
			// 			title: 'การปิดการขาย',
			// 			href: '/content/articles/19'
			// 		},
			// 		{
			// 			title: 'การบริหารทีม',
			// 			href: '/content/articles/20'
			// 		},
			// 		{
			// 			title: 'คำถามที่พบบ่อย',
			// 			href: '/content/articles/21'
			// 		},
			// 	]
			// },
			{
				id: 'events',
				title: 'nav.event',
				href: '/events',
				icon: EventNoteIcon
			},
			{
				id: 'news',
				title: 'nav.news',
				href: '/news',
				icon: ChatOutlinedIcon
			},
			// {
			// 	id: 'pages',
			// 	title: 'nav.page',
			// 	href: '/pages',
			// 	icon: AspectRatioOutlinedIcon
			// },
			{
				id: 'settings',
				title: 'nav.settings.default',
				href: '/settings',
				icon: TuneOutlinedIcon,
				items: [
					{
						title: 'nav.settings.general',
						href: '/settings/profile/general'
					},
					{
						title: 'nav.settings.password',
						href: '/settings/profile/security'
					}
				]
			},
		]
	},
	// {
	// 	subheader: 'nav.org.default',
	// 	items: [
	// 		{
	// 			id: 'content_onlines',
	// 			title: 'nav.org.onlines',
	// 			href: '/content/onlines/categories',
	// 			icon: VideoLibraryRoundedIcon,
	// 			label: () => <Label color={colors.teal.A100}>New</Label>
	// 		},
	// 		{
	// 			id: 'content_steps',
	// 			title: 'nav.org.steps',
	// 			href: '/content/steps/categories',
	// 			icon: PlaylistAddCheckRoundedIcon,
	// 			label: () => <Label color={colors.teal.A100}>New</Label>
	// 		},
	// 	]
	// },
	{
		subheader: 'nav.content.default',
		items: [
			// {
			// 	id: 'content_articles',
			// 	title: 'nav.content.articles',
			// 	href: '/content/articles/categories',
			// 	icon: BookOutlinedIcon,
			// },
			{
				id: 'content_videos',
				title: 'nav.content.videos',
				href: '/content/videos/categories',
				icon: MovieOutlinedIcon,
			},
		]
	},
	// {
	// 	subheader: 'nav.member.default',
	// 	items: [
	// 		{
	// 			id: 'guests',
	// 			title: 'nav.member.guests',
	// 			href: '/guests',
	// 			icon: NaturePeopleIcon
	// 		},
	// 		{

	// 			id: 'members',
	// 			title: 'nav.member.members',
	// 			href: '/members',
	// 			icon: FaceIcon
	// 		}
	// 	]
	// },
	{
		subheader: 'nav.kpi.default',
		items: [
			{
				id: 'kpi_achievements',
				title: 'nav.kpi.achievements.default',
				href: '/kpi/achievements',
				icon: AssessmentIcon,
				items: [
					{
						title: 'nav.kpi.achievements.passport',
						href: '/kpi/achievements/passport'
					},
					{
						title: 'nav.kpi.achievements.history',
						href: '/kpi/achievements/history'
					}
				]
			},
			{
				id: 'kpi_leaders',
				title: 'nav.kpi.leaders',
				href: '/kpi/leaders',
				icon: CakeRoundedIcon
			}
		]
	},
	{
		subheader: 'nav.star.default',
		items: [
			{

				id: 'learners',
				title: 'nav.star.learners',
				href: '/learners',
				icon: CollectionsBookmarkOutlinedIcon
			},
			// {

			// 	id: 'promoters',
			// 	title: 'nav.star.promoters',
			// 	href: '/promoters',
			// 	icon: ImportantDevicesRoundedIcon
			// },
			{

				id: 'qualified',
				title: 'nav.star.qualified',
				href: '/qualified',
				icon: RecentActorsOutlinedIcon
			},
		]
	},
	// {
	// 	subheader: 'คลังสื่อ',
	// 	items: [
	// 		{

	// 			id: 'drive',
	// 			// id: 'content_videos',
	// 			title: 'Echo Drive',
	// 			href: 'https://drive.google.com',
	// 			// href: 'https://drive.google.com/drive/folders/1DfoGKJteYeQ5qGN0wIWEboaIEcjhsVhR',
	// 			icon: PermMediaRoundedIcon
	// 		},
	// 	]
	// },
	// {
	// 	subheader: 'nav.account.default',
	// 	items: [
	// 		{
	// 			id: 'accounts',
	// 			title: 'nav.account.accounts',
	// 			href: '/accounts',
	// 			icon: HowToRegRoundedIcon,
	// 		},
	// 		{
	// 			id: 'account_orders',
	// 			title: 'nav.account.orders',
	// 			href: '/orders',
	// 			icon: LocalMallIcon,
	// 			items: [
	// 				{
	// 					title: 'nav.shopping.customer_orders.orders',
	// 					href: '/orders/summary'
	// 				},
	// 				{
	// 					title: 'nav.shopping.customer_orders.waiting',
	// 					href: '/orders/waiting'
	// 				},
	// 				{
	// 					title: 'nav.shopping.customer_orders.tracking',
	// 					href: '/orders/tracking'
	// 				}
	// 			]
	// 		}
	// 	]
	// },
	// {
	// 	subheader: 'nav.shopping.default',
	// 	items: [
	// 		// {
	// 		// 	id: 'stock',
	// 		// 	title: 'nav.shopping.stock.default',
	// 		// 	href: '/stock',
	// 		// 	icon: StorageRoundedIcon,
	// 		// 	items: [
	// 		// 		{
	// 		// 			title: 'nav.shopping.stock.inventories',
	// 		// 			href: '/stock/inventories'
	// 		// 		},
	// 		// 		{
	// 		// 			title: 'nav.shopping.stock.movements',
	// 		// 			href: '/stock/movements'
	// 		// 		}
	// 		// 	]
	// 		// },
	// 		{
	// 			id: 'order_member',
	// 			title: 'nav.shopping.member_orders',
	// 			href: '/member/orders',
	// 			icon: StoreMallDirectoryRoundedIcon,
	// 		},
	// 		{
	// 			id: 'order_agent',
	// 			title: 'nav.shopping.agent_orders',
	// 			href: '/agent/orders',
	// 			icon: StorefrontRoundedIcon,
	// 		},
	// 		// {
	// 		// 	id: 'order_customer',
	// 		// 	title: 'nav.shopping.customer_orders.default',
	// 		// 	href: '/orders',
	// 		// 	icon: LocalMallIcon,
	// 		// 	items: [
	// 		// 		{
	// 		// 			title: 'nav.shopping.customer_orders.orders',
	// 		// 			href: '/orders/summary'
	// 		// 		},
	// 		// 		{
	// 		// 			title: 'nav.shopping.customer_orders.waiting',
	// 		// 			href: '/orders/waiting'
	// 		// 		},
	// 		// 		{
	// 		// 			title: 'nav.shopping.customer_orders.tracking',
	// 		// 			href: '/orders/tracking'
	// 		// 		}
	// 		// 	]
	// 		// },
	// 	]
	// },
	{
		subheader: 'nav.redeem.default',
		items: [
			{
				id: 'redeem_gifts',
				title: 'nav.redeem.gifts',
				href: '/redeem/gifts',
				icon: CardGiftcardRoundedIcon
			},
			{
				id: 'redeem_history',
				title: 'nav.redeem.histories',
				href: '/redeem/histories',
				icon: HistoryRoundedIcon
			},
		]
	},
	// {
	// 	subheader: 'nav.apps.default',
	// 	items: [
	// 		// {
	// 		// 	id: 'apps_leads',
	// 		// 	title: 'nav.leads',
	// 		// 	href: '/leads',
	// 		// 	icon: PhoneIcon,
	// 		// },
	// 		{
	// 			id: 'apps_shipping',
	// 			title: 'nav.shipping.default',
	// 			href: '/apps/shipping/general',
	// 			icon: MotorcycleOutlinedIcon,
	// 			items: [
	// 				{
	// 					title: 'nav.shipping.general',
	// 					href: '/apps/shipping/general',
	// 				},
	// 				{
	// 					title: 'nav.shipping.couriers',
	// 					href: '/apps/shipping/couriers',
	// 				}
	// 			],
	// 		},
	// 		{
	// 			id: 'apps_payment',
	// 			title: 'nav.payment.default',
	// 			href: '/apps/payment/general',
	// 			icon: PaymentOutlinedIcon,
	// 			items: [
	// 				{
	// 					title: 'nav.payment.general',
	// 					href: '/apps/payment/general',
	// 				},
	// 				{
	// 					title: 'nav.payment.methods',
	// 					href: '/apps/payment/methods',
	// 				}
	// 			],
	// 		},
	// 		{
	// 			id: 'apps_liff',
	// 			title: 'nav.apps.liff',
	// 			href: '/apps/liff',
	// 			icon: SpeakerNotesOutlinedIcon,
	// 			label: () => <Label color={colors.blue[100]}>Hot</Label>
	// 		},
	// 		{
	// 			id: 'apps_page',
	// 			title: 'nav.apps.page',
	// 			href: '/apps/page',
	// 			icon: AspectRatioOutlinedIcon,
	// 			label: () => <Label color={colors.blue[100]}>Hot</Label>
	// 		},
	// 		// {
	// 		// 	id: 'apps_facebook_group',
	// 		// 	title: 'nav.apps.facebook_group',
	// 		// 	href: '/apps/facebook-group',
	// 		// 	icon: FacebookIcon,
	// 		// },
	// 	],
	// },
];

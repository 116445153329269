import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import Loading from './Loading';
import Results from './Results';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles(() => ({
	root: {
	},
	results: {
	}
}));

const TeamBoard = () => {
	const classes = useStyles();
	const [objects, setObjects] = useState(null);
	const [total, setTotal] = useState(0);
	const [values, setValues] = useState({
		page: 0,
		sort_by: 'last_login_date',
		sort_order: 'desc',
		rows_per_page: 20
	});

	//	Functions

	useEffect(() => {
		let mounted = true;

		const newValues = {
			...values,
		};
		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_MEMBER,
				{ params: newValues })
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObjects(data.data.items);
							setTotal(data.data.total_rows);
						}
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
	}, [values]);

	const handleSorting = (sortOrder, sortBy) => {
		setValues({
			...values,
			sort_by: sortBy,
			sort_order: sortOrder
		});
	};

	return (
		<div
			className={classes.root}
		>
			{!objects && (
				<Loading />
			)}
			{objects && (
				<Results
					className={classes.results}
					objects={objects}
					onSorting={handleSorting}
					rows={values.rows_per_page}
					total={total}
				/>
			)}
		</div>
	);
};

export default TeamBoard;
